import React from "react";

const Footer = () => (
  <footer className="d-flex justify-content-center py-48 bg-green text-white">
    <a
      href="https://instytutprogramowania.pl/polityka-prywatnosci/"
      target="_blanc"
      className="fs-12"
    >
      Polityka Prywatności
    </a>
  </footer>
);

export default Footer;
