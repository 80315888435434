import React from "react";

import Point from "../../images/point.png";
import Button from "../common/Button.component";

const PriceSection = () => (
  <section className="price-section bg-green">
    <h2 className="title text-center mb-lg-64 mb-40">
      Co otrzymasz po zakupie szkolenia?
    </h2>
    <div className="smaller-container mx-auto">
      <p className="fs-mobile-20 px-16 fs-32 my-24 text-white">
        <strong>
          <img src={Point} alt="Point Icon" className="point-icon mr-8" /> 3
          Miesięczny Program Młody Senior:
        </strong>{" "}
        Wartość 1299 PLN
      </p>
      <p className="fs-mobile-20 px-16 fs-32 my-24 text-white">
        <strong>
          <img src={Point} alt="Point Icon" className="point-icon mr-8" /> 3
          Bonusowe moduły:
        </strong>{" "}
        Wartość 550 PLN
      </p>
      <p className="fs-mobile-20 px-16 fs-32 my-24 text-white">
        <strong>
          <img src={Point} alt="Point Icon" className="point-icon mr-8" />{" "}
          Cotygodniowe Spotkania Mastermind ze mną:
        </strong>{" "}
        Wartość 1500 PLN
      </p>
      <p className="fs-mobile-20 px-16 fs-32 my-24 text-white">
        <strong>
          <img src={Point} alt="Point Icon" className="point-icon mr-8" />{" "}
          Dostęp do nagrań z poprzednich spotkań:
        </strong>{" "}
        Wartość 600 PLN
      </p>
      <p className="fs-mobile-20 px-16 fs-32 my-24 text-white">
        <strong>
          <img src={Point} alt="Point Icon" className="point-icon mr-8" />{" "}
          Dożywotni Dostęp Do Kursu oraz Aktualizacji Materiałów:
        </strong>{" "}
        Wartość: 500 PLN
      </p>
      <p className="fs-mobile-20 px-16 fs-32 my-24 text-white">
        <strong>
          <img src={Point} alt="Point Icon" className="point-icon mr-8" />{" "}
          Dożywotni Dostęp Do Zamkniętej Grupy:
        </strong>{" "}
        Wartość BEZCENNE
      </p>
      <p className="fs-mobile-20 px-16 fs-32 my-24 text-white">
        <strong>
          <img src={Point} alt="Point Icon" className="point-icon mr-8" />{" "}
          <span className="text-underline">
            Tylko w Specjalnej Ofercie Cały Pakiet Dostępny Jest Jedynie Za 899
            PLN!!!!
          </span>
        </strong>
      </p>
      <div className="d-flex justify-content-center w-100 mt-64">
        <Button bigger center>
          DOŁĄCZ JUŻ DZIŚ
        </Button>
      </div>
    </div>
  </section>
);

export default PriceSection;
