import React from "react";

import Point from "../../images/point.png";
import Button from "../common/Button.component";

interface IProps {
  title: string;
  text: string;
}

export const Reason: React.FC<IProps> = ({ title, text }) => (
  <div className="d-flex flex-column col-12 col-lg-6 my-16 my-lg-0 px-lg-40 px-0">
    <h3 className="fs-32 fs-mobile-20 bold mb-lg-24 mb-16">
      <img src={Point} alt="Point Icon" className="point-icon" />
      {title}
    </h3>
    <p className="fs-20 fs-mobile-16">{text}</p>
  </div>
);

const WhatYouGetSection = () => (
  <section className="what-you-get-section bg-green">
    <div className="container">
      <h2 className="title text-center mb-lg-64 mb-32">
        Co dostajesz w tym kursie?
      </h2>
      <div className="row">
        <Reason
          title="Zaczniesz Uczyć Się Programowania"
          text="Zaczynamy kompletnie od zera. Podczas tego kursu rozbiję wszystkie twoje obawy i problemy, razem wybierzemy dla Ciebie najlepszy język programowania i pokażę Ci skąd czerpać o nim wiedze. Dodatkowo nauczę Cię wszystkich tajników rekrutacji, abyś mógł wyróżnić się na tle setek innych kandydatów! Prawdziwa droga od zera do zawodowego programisty."
        />
        <Reason
          title="Poznasz Cały Proces"
          text={`Przez wszystko przeprowadzę Cię dosłownie "za rączkę". Poznasz cały proces zdobywania pierwszej pracy od A do Z. Nie wiesz czy nadajesz się do programowania? Jaki język programowania wybrać? Skąd się go uczyć? Wszystko wyjaśniam w poszczególnych lekcjach tego szkolenia!`}
        />
      </div>
      <div className="row">
        <Reason
          title="Otrzymasz 10-Tygodniowy Program Online"
          text="Otrzymasz dostęp do 10 modułów w pełni skupionych na zdobyciu pierwszej pracy w branży IT. Idealne tempo nauki to jeden moduł każdego tygodnia. Dodatkowo - kiedy poznam nową, lepszą technikę lub okaże się, że nie poruszyłem czegoś istotnego, każda lekcja będzie aktualizowana."
        />
        <Reason
          title="Zbudujesz Własne Portfolio i Idealne CV"
          text="W szkoleniu nauczę Cię jak budować portfolio projektów, które jest kluczowe do dostania pierwszej pracy. Dzieki dobremu portfolio zmaksymalizujesz swoje szanse na rynku! Czekają Cię dwa moduły w pełni poświęcone tajnikom rekrutacji - jeden z perkspetywy programisty (czyli mojej), a drugi prowadzony przez zawodowego rekrutera z największej firmy w Polsce zajmującej się ofertami pracy dla programistów!"
        />
      </div>
      <div className="row">
        <Reason
          title="Otrzymasz Praktyczną Wiedzę Od specjalistów"
          text="W szkoleniu opisuję wszystkie najpopularniejsze języki programowania. Sam specjalizuję się tylko w Javascripcie i nie czuję się na siłach opowiadać o innych językach typu C++ czy Java. Wszystkie informacje zawarte w szkoleniu to wiedza, którą pozyskałem od specjalistów w swoich dziedzinach. Spędziłem dziesiątki godzin na pisaniu z programistami danych nisz, by zapewnić Ci wiedzę najwyższej jakości pozyskaną od samych praktyków!"
        />
        <Reason
          title="Poznasz Ludzi Takich Jak Ty"
          text="Największą wartością tego szkolenia jest dostęp do naszej zamkniętej grupy, w której poznasz ludzi, również zaczynających swoją przygodę z programowaniem. Stworzymy niesamowite miejsce, w którym nawiążesz wartościowe kontakty, miejsce gdzie będziemy wymieniać się doswiadczeniami i wiedzą oraz nawzajem sobie pomagać!"
        />
      </div>
      <div className="row justify-content-center">
        <Reason
          title="Otrzymasz Liczne Bonusy"
          text="Otrzymasz masę bonusów o łącznej wartości znacznie przekraczającej cenę kursu. Są to między innymi dodatkowe moduły, które pomogą Ci przejść cały proces oraz wiele sesji Pytań i Odpowiedzi, w których odpowiem na wszystkie Twoje pytania oraz wątpliwości i wiele, wiele innych..."
        />
      </div>
      <div className="d-flex justify-content-center w-100 mt-64">
        <Button bigger center>
          DOŁĄCZ JUŻ DZIŚ
        </Button>
      </div>
    </div>
  </section>
);

export default WhatYouGetSection;
