import React from "react";

import Img from "../../images/warranty.png";
import Button from "../common/Button.component";

const WarrantySection = () => (
  <section className="warranty-section bg-green">
    <h2 className="title text-center mb-lg-64 mb-40 fs-mobile-40">
      Nic nie ryzykujesz! ❤️
    </h2>
    <div className="container">
      <div className="row">
        <div className="col-12 mb-lg-0 mb-24 col-lg-6 d-flex  justify-content-lg-end justify-content-center align-items-start px-lg-80">
          <img className="warranty-icon" src={Img} alt="Warranty Icon" />
        </div>
        <div className="col-12 col-lg-6">
          <h3 className="fs-32 bold">
            14-dniowa gwarancja satysfakcji!
            <br />
            Bez żadnych pytań!
          </h3>
          <p className="fs-20 mb-48">
            Masz aż 14 dni na zwrot szkolenia. Możesz na spokojnie się
            zalogować, sprawdzić wszystkie lekcje i jeżeli uznasz, że nie tego
            się spodziewałeś to zwrócę Ci wszystkie pieniądze. Nie musisz nawet
            podawać powodu! Bardzo dbam o to, by 100% kursantów było
            zadowolonych z tego szkolenia. To dla mnie priorytet.
          </p>
          <h3 className="fs-32 bold">Czas jest tu kluczowy!</h3>
          <p className="fs-20">
            Wyślę Ci pieniądze tego samego dnia, którego złożysz reklamacje. Bez
            dziwnych ankiet, bez długich procesów. Piszesz maila i następnego
            dnia masz już pieniążki na koncie!
          </p>
        </div>
        <div className="d-flex justify-content-center w-100 mt-64">
          <Button bigger center>
            DOŁĄCZ JUŻ DZIŚ
          </Button>
        </div>
      </div>
    </div>
  </section>
);

export default WarrantySection;
