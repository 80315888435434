import clsx from "clsx";
import React from "react";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariants;
  bigger?: boolean;
  center?: boolean;
}

export enum ButtonVariants {
  "primary",
  "secondary",
}

const Button: React.FC<IProps> = ({
  children,
  variant = ButtonVariants.primary,
  className,
  center = false,
  bigger = false,
  onClick = () =>
    (window.location.href =
      "https://instytutprogramowania.pl/zamowienie/?add-to-cart=37"),
  ...props
}) => {
  return (
    <div>
      <button
        onClick={onClick}
        className={clsx(
          "button fs-mobile-24 py-16 px-24 d-flex align-items-center justify-content-center fw-bold",
          className,
          bigger ? "fs-40 py-lg-24 px-lg-56" : "fs-32 py-lg-24 px-lg-32",
          {
            "bg-white text-black border-black":
              variant === ButtonVariants.primary,
            "bg-black text-white border-black":
              variant === ButtonVariants.secondary,
          }
        )}
        {...props}
      >
        {children}
      </button>
      <p
        className={clsx(
          "mt-16 d-flex align-items-center",
          center ? "justify-content-center" : "ml-16",
          bigger ? "fs-32" : "fs-24"
        )}
      >
        <s className={bigger ? "fs-40 mr-16" : "fs-32 mr-12"}>1299zł</s>{" "}
        <strong>899zł</strong>
      </p>
    </div>
  );
};

export default Button;
