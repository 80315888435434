import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./views/Home.view";

import "./scss/main.scss";
import Footer from "./components/Footer.component";
import SaleThankYouPage from "./views/SaleThankYouPage.component";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dzieki-za-zakup" element={<SaleThankYouPage />} />
        </Routes>
      </Router>
      <Footer />
    </>
  );
};

export default App;
