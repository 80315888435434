import React from "react";

import Avatar from "../../images/avatar.jpeg";

const AboutMeSection = () => (
  <section className="about-me-section text-black">
    <h2 className="title text-center mb-lg-56 mb-40">
      Moja historia z programowaniem
    </h2>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 fs-56 d-flex justify-content-center">
          <img src={Avatar} alt="My Image" className="mw-100 avatar" />
        </div>
        <div className="col-12 col-lg-6 text-container">
          <p>
            <strong>Wszystko zaczęło się w czwartej klasie technikum...</strong>{" "}
            <br />
            <br />
            Dopiero wtedy zrozumiałem, że szkoła mnie niczego nie nauczy i
            jeżeli chcę znaleźć pracę w zawodzie to muszę wziąć sprawy w swoje
            ręce - i tak zrobiłem!
            <br />
            <br />
            Po zaledwie dwóch miesiącach nauki na własną rękę okazało się, że
            wiem więcej na temat programowania, niż nauczyciele, którzy powinni
            mnie tego uczyć...
            <br />
            <br />
            Pod koniec technikum (po około 8 miesiącach nauki) udało mi się
            znaleźć pierwszą pracę w zawodzie. Aktualnie od 4 lat pracuję
            zawodowo jako Javascript Developer.
            <br />
            <br />
            Mimo, że udało mi się w miarę szybko osiągnąć sukces zawodowy (mam
            dopiero 22 lata) to z perspektywy czasu widzę wiele błędów, które
            popełniłem w całym procesie.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default AboutMeSection;
