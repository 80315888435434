import React from "react";
import Week1 from "../../images/weeks/1.jpg";
import Week2 from "../../images/weeks/2.jpg";
import Week3 from "../../images/weeks/3.jpg";
import Week4 from "../../images/weeks/4.jpg";
import Week5 from "../../images/weeks/5.jpg";
import Week6 from "../../images/weeks/6.jpg";
import Week7 from "../../images/weeks/7.jpg";
import Week8 from "../../images/weeks/8.jpg";
import Week9 from "../../images/weeks/9.jpg";
import { Reason } from "./WhatYouGetSection.component";

const images = [Week1, Week2, Week3, Week4, Week5, Week6, Week7, Week8, Week9];

const agenda = [
  [
    "1. Powitanie",
    "2. Plan Gry",
    "3. Efekt Końcowy",
    "4. Dlaczego To Robię",
    "5. Jak Przerabiać Szkolenie",
    "6. Błędy i Praca Domowa",
  ],
  [
    "1. Powitanie",
    "2. Kim Jest Programista?",
    "3. Czy Programistą Może Być Każdy?",
    "4. Czy Nie Jest Dla Ciebie Już Za Późno?",
    "5. Czy Nadajesz Się Do Tego Zawodu?",
    "6. Czy To Na Pewno Zawód Przyszłości?",
    "7. Plusy i Minusy Naszej Branży.",
    "8. Mity o Pracy Programisty",
    "9. Pasja w Programowaniu.",
    "10. Gotowy Plan Nauki",
    "11. Czarna Wizja Przyszłości",
    "12. Błędy i Praca Domowa",
  ],
  [
    "1. Powitanie",
    "2. Czy Muszę Mieć Dobre Oceny",
    "3. Liceum vs Technikum",
    "4. Skończyłem Studia/Jestem Na Innym Kierunku, Co Teraz",
    "5. Studia - Czy Są One Niezbędne",
    "6. Studia cz.2 - Inny Punkt Widzenia",
    "7. Samouk vs Inżynier",
    "8. Problemy Szkolnictwa",
    "9. Podsumowanie",
    "10. Błędy i Praca Domowa",
  ],
  [
    "1. Powitanie",
    "2. Najważniejszy Język Do Programowania",
    "3. Backend vs Frontend - O Co z Tym Chodzi?",
    "4. Typy Języków",
    "5. Przedstawienie Wszystkich Języków Programowania",
    "6. Czy Wybór Języka Jest Aż Tak Wiążący?",
    "7. Które Języki Lepiej Omijać?",
    "8. Plusy i Minusy Przefiltrowanych Opcji",
    "9. Obranie Swojej Ścieżki",
    "10. Ostateczna Weryfikacja",
    "11. Błędy i Praca Domowa",
  ],
  [
    "1. Powitanie",
    "2. Jak Utrzymać Motywację w Nauce?",
    "3. Ile Czasu Muszę Poświęcić Na Naukę?",
    "4. Jak Uczyć Się Efektywnie?",
    "5. Likwidacja Rozpraszaczy",
    "6. Bootcampy",
    "7. Książki",
    "8. Kursy Online",
    "9. Dokumentacja",
    "10. Społeczność",
    "11. Jakie Projekty Robić?",
    "12. Klucz Do Sukcesu",
    "13. Wielka, Niewidzialna Ściana",
    "14. Błędy i Praca Domowa",
  ],
  [
    "1. Powitanie",
    "2. Setup Komputerowy",
    "3. LinkedIN",
    "4. System Kontroli Wersji",
    "5. Wtyczki",
    "6. Google i Stack Overflow",
    "7. Błędy i Praca Domowa",
  ],
  [
    "1. Powitanie",
    "2. Gdzie Szukać Pracy?",
    "3. Jak Wygląda Rekrutacja?",
    "4. Analiza Mojego CV",
    "5. CV Bez Doświadczenia Komercyjnego",
    "6. Portfolio",
    "7. Rozmowa Techniczna",
    "8. Zadania Rekrutacyjne",
    "9. Klucz Do Pierwszej Pracy - Jak Się Wyróżnić?",
    "10. Co Powinieneś Umieć Przed Aplikowaniem?",
    "11. Błędy i Praca Domowa",
  ],
  [
    "1. Powitanie",
    "2. Czym Jest Marka Osobista?",
    "3. Czy To Się Opłaca?",
    "4. Marka Osobista Podczas Rekrutacji",
    "5. Korzyści Finansowe",
    "6. Nowe Możliwości",
    "7. Jak i Gdzie Zacząć?",
    "8. Błędy i Praca Domowa",
  ],
  [
    "1. Powitanie",
    "2. Jak Wygląda Praca Programisty?",
    "3. Ile Zarabia Się Na Poszczególnych Stanowiskach?",
    "4. Oczekiwania Juniorów vs Rzeczywistość",
    "5. Sens Pierwszej Pracy",
    "6. Przykładowe Codzienne Projekty",
    "7. Praca w Teamie",
    "8. Codzienne Zadania",
    "9. Czarne Strony",
    "10. Wypalenie - Jak Tego Uniknąć?",
    "11. Błędy i Praca Domowa",
  ],
];
const AgendaSection = () => (
  <section className="agenda-section text-black">
    <h2 className="title text-center mb-56">Opis programu</h2>
    <div className="container">
      <div className="row">
        {agenda.map((item, i) => (
          <div className="col-12 col-lg-4 mb-lg-32">
            <img src={images[i]} alt="Week" className="mw-100" />
            {item.map((lesson) => (
              <p className="fs-16 my-4 px-lg-32">{lesson}</p>
            ))}
          </div>
        ))}
      </div>
    </div>
    <h2 className="title text-center mt-64 mb-48">Darmowe Bonusy!</h2>
    <div className="container">
      <div className="row">
        <Reason
          title="Dodatkowy moduł o Rekrutacji!"
          text="Rekrutacja to bardzo ważny temat. Chce, by każdy z was zmaksymalizował swoje szansę na rynku pracy. Dlatego postanowiłem przygotować dla was bonusowy moduł o rekrutacji, prowadzony przez doświadczonego, zawodowego rekrutera! Dzięki temu otrzymasz informacje z obu stron! Poznasz jak ja, jako programista przygotowuje się do rekrutacji oraz usłyszysz na co zwracają uwagę rekruterzy. Dzięki temu będziesz w stanie zdominować swoją konkurencję!"
        />
        <Reason
          title="Nagrania ze wszystkich spotkań!"
          text="W przedsprzedaży dołączyło do nas ponad 120 osób. Przez pół roku odbyliśmy z kursantami mnóstwo spotkań. Sesje Q&A dotyczące poszczególnych modułów oraz nasze spotkania Mastermind na których omawiamy tworzone przez was projekty oraz pomagamy sobie nawzajem. Wszystkie spotkania były nagrywane oraz wrzucone na platformę. Dzięki temu masz do nich dostęp i możesz sobie je na spokojnie przerobić!"
        />
      </div>
    </div>
  </section>
);

export default AgendaSection;
