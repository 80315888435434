import React from "react";

const AnotherPathSection = () => (
  <section className="about-me-section text-black">
    <h2 className="title text-center mb-lg-56 mb-40">Czy jest inna droga?</h2>
    <div className="container text-center">
      <p>
        <strong className="fs-32">Oczywiście!</strong>
        <br />
        <br />
        Możesz obejrzeć setki nagrań na YouTube, przeczytać książki i
        przechodzić cały proces tak, jak podpowiada Ci intuicja. Pamiętaj
        jednak, że każda zła decyzja może skutkować utratą ogromnej ilości
        czasu. Istnieją też trywialne błędy, które pozbawią Cię szansy na dobry
        start w branży IT.
        <br />
        <br />
        <strong>
          Ponad 90% osób zaczynających naukę programowania nie znajduje pracy w
          zawodzie.
        </strong>
        <br />Z czegoś to wynika... Robienie rzeczy intyuicyjnie może
        zaskutkować tym, że znajdziesz się w tej większości.
        <br />
        <br />
        Możesz też rozpocząć 10-Tygodniowe szkolenie i ominąć te wszystkie błędy
        co znacznie zwiększy Twoje szanse na rynku pracy.
      </p>
    </div>
  </section>
);

export default AnotherPathSection;
