import React from "react";

const SaleThankYouPage = () => (
  <main>
    <section className="bg-green text-white">
      <h1 className="fs-60 text-center">
        Gratuluje! Dzięki, że dołaczyłeś! ❤️
      </h1>
    </section>
    <section className="text-black">
      <div className="container">
        <h2 className="text-center fs-48 bold mb-48">
          W ciągu 15 minut dostaniesz ode mnie maila!
        </h2>
        <div className="fs-20">
          <h3 className="bold mb-16">
            W środku znajdziesz link na którym ustawisz hasło do swojego konta
            na platformie kursu. <br />
            Wbijaj na swoją skrzynkę mailową!
          </h3>
          <a target="_blank" href="https://mail.google.com/" rel="noreferrer">
            - Gmail
          </a>
          <br />
          <a target="_blank" href="https://www.onet.pl/poczta" rel="noreferrer">
            - Onet
          </a>
          <br />
          <a
            target="_blank"
            href="https://profil.wp.pl/login/login.html"
            rel="noreferrer"
          >
            - WP.pl
          </a>
          <br />
          <a
            target="_blank"
            href="https://poczta.o2.pl/zaloguj"
            rel="noreferrer"
          >
            - O2.pl
          </a>
          <br />
          <a
            target="_blank"
            href="https://poczta.interia.pl/logowanie/"
            rel="noreferrer"
          >
            - Interia.pl
          </a>
          <br />
          <a
            target="_blank"
            href="https://oauth.gazeta.pl/poczta/auth"
            rel="noreferrer"
          >
            - Gazeta.pl
          </a>
          <br />
          <a target="_blank" href="https://login.yahoo.com/" rel="noreferrer">
            - Yahoo.com
          </a>
        </div>
        <div className="fs-20">
          <h3 className="bold mt-40">WAŻNE: Sprawdź wszędzie!</h3>Jeżeli nie
          widzisz maila z linkiem to koniecznie sprawdź foldery: <br />
          <b>"Oferty"</b>, <br />
          <b>"Społeczności", </b> <br />
          <b>"Powiadomienia", </b> <br />
          <b>"Spam"</b> <br /> <br />
        </div>
        <div className="fs-20">
          <h3 className="bold">Co dalej?</h3>
          Gdy już ustawisz hasło do swojego konta to będziesz mógł zalogować się
          na platformę i zacząć przerabiać kurs! Polecam zacząć od razu i
          przerobić moduł 1. Jest bardzo krótki.
        </div>
        <div className="fs-20">
          <h2 className="text-center fs-48">UWAGA!</h2>
          <strong>
            Jeśli do <strong>30 minut</strong> mail nie przyjdzie,{" "}
          </strong>
          to napisz wiadomość na adres{" "}
          <a href="mailto:bartek@instytutprogramowania.com">
            bartek@instytutprogramowania.com
          </a>
          . Wtedy sprawdzę co poszło nie tak i wyśle Ci go ręcznie {":)"}
        </div>
      </div>
    </section>
  </main>
);

export default SaleThankYouPage;
