import React from "react";

const FAQSection = () => (
  <section className="text-center text-black">
    <h2 className="title mb-72">Często zadawane pytania</h2>

    <div className="container">
      <h4 className="bold fs-32 mb-8">Jak długo mam dostęp do szkolenia?</h4>
      <p className="mb-56">
        Dostęp do szkolenia jest dożywotni. Oprócz dożywotniego dostępu
        otrzymujesz dostęp do wszystkich następnych edycji szkolenia oraz
        wszystkich aktualizacji, jeżeli takowe będą potrzebne.
      </p>
      <h4 className="bold fs-32 mb-8">
        Czy potrzebuje czegoś, by przystąpić do szkolenia?
      </h4>
      <p className="mb-56">
        Jedyne czego potrzebujesz to komputer i dostęp do internetu. Program
        przeprowadzi Cię od kompletnego zera do pierwszej pracy w branży IT. Nie
        potrzebujesz żadnych umiejętności, żadnej wiedzy, przez wszystko
        przeprowadzę Cię "za rączkę".
      </p>
      <h4 className="bold fs-32 mb-8">
        Czy w tym kursie nauczę się programować?
      </h4>
      <p className="mb-56">
        Tak! Mimo, ze to nie jest kurs programowania w danym języku to nauczysz
        się z nami programować! Jest to szkolenie wyjaśniające jak zostać
        programistą krok po kroku. W tym kursie uczę Cię całego procesu, od zera
        do pierwszej pracy w IT. Dosłownie każdy mój kursant zaczął programować
        po przerobieniu tego szkolenia! Teraz tworzą niesamowite aplikacje,
        które będziesz miał okazje zobaczyć, gdy do nas dołączysz {`:)`}
      </p>
      <h4 className="bold fs-32 mb-8">
        Jak przekonać rodzica do zakupu szkolenia?
      </h4>
      <p className="mb-56">
        Programowanie daje dziecku mnóstwo benefitów. Wiem, że lwia część mojej
        widowni to osoby, które są jeszcze uzależnione finansowo od rodziców.
        Przygotowałem broszurkę, którą możesz pokazać rodzicowi jeżeli ma jakieś
        obawy, bądź wątpliwości dotyczące zakupu!{" "}
        <a
          target="_blank"
          href="https://instytutprogramowania.pl/wp-content/uploads/2023/01/broszurka.pdf"
          rel="noreferrer"
        >
          POBIERZ BROSZURKĘ TUTAJ
        </a>
      </p>
      <h4 className="bold fs-32 mb-8">
        Co jeśli mam z czymś problem lub coś jest niejasne?
      </h4>
      <p className="mb-56">
        Po prostu napisz do mnie na adres e-mail:{" "}
        <a href="mailto:bartek@instytutprogramowania.com">
          bartek@instytutprogramowania.com
        </a>
      </p>
    </div>
  </section>
);

export default FAQSection;
