import React from "react";

import HeroSection from "../components/HeroSection.component";
import AboutMeSection from "../components/Home/AboutMeSection.component";
import WhatYouGetSection from "../components/Home/WhatYouGetSection.component";
import AnotherPathSection from "../components/Home/AnotherPathSection.component";
import PriceSection from "../components/Home/PriceSection.component";
import AgendaSection from "../components/Home/AgendaSection.component";
import WarrantySection from "../components/Home/WarrantySection.component";
import FAQSection from "../components/Home/FAQSection.component";

const Home = () => (
  <main>
    <HeroSection />
    <AboutMeSection />
    <WhatYouGetSection />
    <AnotherPathSection />
    <PriceSection />
    <AgendaSection />
    <WarrantySection />
    <FAQSection />
  </main>
);

export default Home;
