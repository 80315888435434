import React from "react";

import Bg from "../images/bg.webp";
import Button from "./common/Button.component";

const HeroSection = () => (
  <section className="hero-section d-flex align-items-center justify-content-center">
    <div
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)), url("${Bg}")`,
      }}
      className="hero-section-bg"
    />
    <div className="container">
      <div className="row flex-column-reverse flex-lg-row">
        <div className="col-lg-6 col-12 text-center text-lg-start d-flex flex-column align-items-center align-items-lg-start text-container">
          <h1 className="fs-mobile-40">CHCESZ ZOSTAĆ PROGRAMISTĄ?</h1>
          <p className="mb-32 mt-16 pr-lg-40">
            To szkolenie przygotuje Cię do tego <strong>w 100%!</strong>
            <br />
            <br />
            Krok po kroku przeprowadzę Cię przez cały proces zdobywania swojej
            pierwszej pracy w IT.
          </p>
          <Button className="mx-lg-0">DOŁĄCZ JUŻ DZIŚ</Button>
        </div>
        <div className="col-lg-6 col-12 px-32 px-lg-0">
          <div
            className="vimeo"
            style={{ padding: "56.25% 0px 0px", position: "relative" }}
          >
            <iframe
              src="https://player.vimeo.com/video/789507113?h=12ca9cd250&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              title="VIDEO SPRZEDAZOWE"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>
    </div>
  </section>
);

export default HeroSection;
